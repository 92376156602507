.container {
    padding: 20px;
  }
  
  .image_container1 {
    background-color: rgb(9, 110, 233);
    height: 40vh;
    width: 40vh;
    border-radius: 0px 0px 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }
  
  .image_container1 img {
    height: 25vh;
    width: auto;
  }
  
  .image_container1 p {
    color: aliceblue;
    text-align: center;
  }
  
  
  .image_container2 {
    background-color: rgb(230, 10, 10);
    height: 40vh;
    width: 40vh;
    border-radius: 0px 0px 0px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }
  
  .image_container2 img {
    height: 25vh;
    width: auto;
  }
  
  .image_container2 p {
    color: aliceblue;
    text-align: center;
  }
  
  
  .image_container3 {
    background-color: rgb(5, 227, 171);
    height: 40vh;
    width: 40vh;
    border-radius: 0px 30px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }
  
  .image_container3 img {
    height: 25vh;
    width: auto;
  }
  
  .image_container3 p {
    color: aliceblue;
    text-align: center;
  }
  
  
  .image_container4 {
    background-color: rgb(239, 199, 1);
    height: 40vh;
    width: 40vh;
    border-radius: 30px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }
  
  .image_container4 img {
    height: 25vh;
    width: auto;
    color:black;
  }
  
  .image_container4 p {
    color: aliceblue;
    text-align: center;
  }
  .container {
    padding: 20px;
  }
  
  .box_01 {
    height: auto; /* Adjust height to fit content */
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(rgb(221,248,237), rgb(209,227,245));
    border-bottom-color: hsla(89, 43%, 51%, 0.3);
    border-bottom: 10px solid #0146bd; /* 10px bottom border */
  }  
  .box_01 h4 {
    font-weight: 600;
  }
  
  .box_01 i {
    color: rgb(174, 16, 103);
    font-size: x-large;
  }
  
  .box_01 .d-flex {
    align-items: center;
  }
  
  .box_01 p {
    margin-top: 10px;
    text-align: justify;
  }
  
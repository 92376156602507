.react_player{

        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden; /* Ensure the content stays within the wrapper */
      }

      .content_0001 {
        position: absolute;
       top: 50%;
       left: 60%;
        transform: translate(-50%, -50%);
        z-index: 5; /* Place the content in front of the video */
        color: white;
        padding: 80px 40px 20px 20px;
      }
          

/* src/Components/Offerlabs.css */

.box_o1 {
    height: 50vh;
    width: auto;
    border-color: black;
    border-radius: 20px;
    border: 1px solid #ccc;
    margin: 10px; /* Add margin to create a gap between the elements */
    background-image: linear-gradient(rgb(221,248,237), rgb(209,227,245));
    display: flex; /* Added */
    flex-direction: column; /* Added */
    justify-content: center; /* Added */
    align-items: center; /* Added */
  }
  
  .box_o1 p {
    text-align: start;
  }
  
  .box_o1 i,
  .box_o1 h4,
  .box_o1 p {
    font-size: large;
  }
  
  .box_o1 i {
    font-size: xx-large;
  }
  
.btn1111 {
    background-color: rgb(21, 5, 196);
    color: white;
    border-radius: 0px 15px 15px 15px;
    font-size: 15px;
    margin-right: 30px;
    padding: 10px 20px;
    font-family: 'Poppins', sans-serif;
    height: 50px; /* Fixed height */
    width: 200px; /* Fixed width */
    line-height: 30px; /* Adjust if needed to vertically center text */
    text-align: center; /* Center the text horizontally */
    display: inline-block; /* Ensure the element respects the width and height */
    box-sizing: border-box; /* Include padding and border in the total width and height */
  }
  
  .contact_btn {
    display: flex;
    justify-content: center; /* Center the button horizontally */
    align-items: center; /* Center the button vertically */
  }
.player_wrapper01 {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden; /* Ensure the content stays within the wrapper */
}

.react_player01 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover; /* Cover the entire area without distortion */
    z-index: 1; /* Place the video at the back */
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7));
}

*{
    margin: 0;
    padding: 0;
}

/* .bg5{
  height:80vh;
  width: 390px;
  margin-right: -30px;
  /* margin-left: 30px; */
  /* border-radius: 20px; */

/* } */ 

.text_alt{
    text-align: start;
    color: rgb(57, 51, 51);

    /* text-align-last:left; */
}
.top_det{
    text-align: end;
    background-image: url("../../LifeScience/ClientLabsTestimonial/Assets/building2.jpg");
    height:70vh;
   width: 700px;
    border-radius: 20px;
    background-size: cover;

}
.text1 {
    background-image: url("../../LifeScience/ClientLabsTestimonial/Assets/ball2.png");
    width:700px;
    height: 70vh;
    background-color: cyan;
    text-align: center;
    /* margin-top: 5px; */
    border-radius:left;
    translate: 5px;
    background-size: cover;
    border-radius: 10px;
    
    
}
.text_2{

    text-align: start;
}
.texter{
    text-align: center;
}
/* src/Components/Aligi/Aligi.css */

.container11 {
  height: 130vh;
  width: 100%;
  background-image: linear-gradient(to right, rgb(231,246,244) 80%, rgb(130,227,186) 20%);
}

.btn111 {
  background-color: rgb(130,227,186);
  color: white;
  border-radius: 0px 15px 15px 15px;
  font-size: 15px;
  margin-right: 30px;
  padding: 10px 20px;
  border-color: rgb(130,227,186);
  font-family: 'Poppins', sans-serif;
}

.content1 h1 span {
  color: rgb(130,227,186);
}

.content1 h1 {
  font-weight: 800;
  padding: 20px;
}

.container {
  margin-top: 40px;
}

.heading1, .heading2, .heading3 {
  font-family: 'Poppins', sans-serif;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.paragraph1, .paragraph2, .paragraph3 {
  font-family: 'Poppins', sans-serif;
  margin-bottom: 10px;
}

.img1, .img2, .img3 {
  border-radius: 10px;
}

.text-center img {
  margin: 0 auto;
}

.text_center h1, .text_center p {
  text-align: start;
}

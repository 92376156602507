.Content_Development18 {
    height: 80vh;
    width: 100%;
    background: 
    linear-gradient(rgba(29, 3, 3, 0.855), rgba(22, 20, 20, 0.2)), 
    url("../../Mobile/MobileOverview/Assets/Mb6.jpg");    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 20vh;
  }

  .Content_Development18 h1{
     color: white;
     font-weight: 700;
     padding: 5px;
     font-size: 60px;

  }
  
  .Content_Development18 p{
    color: white;
    font-size: 30px;

 }
 
.Button_Contain {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .Button_Contain button {
    background-color: #faf9f9;
    color: #786e6e;
    border: none;
    padding: 10px 20px;
    margin: -10px 80px 0px 0px;
    font-size: 26px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .Button_Contain button:hover {
color: orange;  }

.Overview_content  p{
  color: black;
 font-size: 20px;
  margin-left: 20px;
  font-weight: 500;
}

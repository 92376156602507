@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
* {
  font-family: "Poppins", sans-serif;
}

.mobile_img1 {
  background: linear-gradient(
      to bottom,

      rgba(4, 9, 30, 0.7),
      rgba(4, 9, 30, 0.7)
    ),
    url("../../Banking/BankService/Assets/1.jpeg");
  height: 70vh;
  width: auto;
  border-radius: 10px;
  margin: 10px;
}

.mobile_img2 {
  background: linear-gradient(
      to bottom,

      rgba(4, 9, 30, 0.7),
      rgba(4, 9, 30, 0.7)
    ),
    url("../../Banking/BankService/Assets/2.jpeg");
  height: 70vh;
  width: auto;
  border-radius: 10px;
  margin: 10px;
}

.mobile_img3 {
  background: linear-gradient(
      to bottom,

      rgba(4, 9, 30, 0.7),
      rgba(4, 9, 30, 0.7)
    ),
    url("../../Banking/BankService/Assets/3%20(1).jpeg");
  height: 70vh;
  width: auto;
  border-radius: 10px;
  margin: 10px;
}

.mobile_img4 {
  background: linear-gradient(
      to bottom,

      rgba(4, 9, 30, 0.7),
      rgba(4, 9, 30, 0.7)
    ),
    url("../../Banking/BankService/Assets/4.jpeg");
  height: 70vh;
  width: auto;
  border-radius: 10px;
  margin: 10px;
}

.mobile_img5 {
  background: linear-gradient(
      to bottom,

      rgba(4, 9, 30, 0.7),
      rgba(4, 9, 30, 0.7)
    ),
    url("../../Banking/BankService/Assets/5.jpeg");
  height: 70vh;
  width: auto;
  border-radius: 10px;
  margin: 10px;
}

.Line_color {
  color: orange;
}

.txt_01 {
  color: white;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 120px;
  font-size: 40px;
}

.txt_02 {
  color: white;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 20px;
}
.BankServices_Content p {
  margin: 29px 0;
  font-size: larger;
  letter-spacing: 3px; /* Adjusts the space between letters */
  /* Adjusts the space between lines */
}

.overall_Content {
    background-image: linear-gradient(to bottom right, rgb(254,191,16), rgb(243, 148, 5));
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .Content_item1 ul{
    color: white;
    font-size: x-large;
  }
  .image_ooo1 img {
    height: 100vh;
    width:80vh;
    padding-right: 25px;
  }
  
  .image_ooo1 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Content_item1 {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: start;
    padding: 20px;
  }
  .Content_item1 h1{
    font-size: 13vh;
  }
  .Content_item1 span{
    color: black;
  }

  .color_round1 {
    height: 20vh;
    width: 20vh;
    background-color: rgb(254,214,3);
    border-radius: 50%;
    
  }
  .color_round1 h1{
    padding-top: 60px;
    padding-left: 50px;
    margin-top: 20px;
    font-size: 10vh;

  }
  .color_roundh4{
    padding-top: 20px;
    padding-left: 50px;
  }
  
  .color_round2 {
    height: 20vh;
    width: 20vh;
    background-color: rgb(239,113,121);
    border-radius: 50%;
    
  }
  
  .color_round2 h1{
    padding-top: 60px;
    padding-left: 50px;
    margin-top: 20px;
    font-size: 10vh;

  }
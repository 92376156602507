.people_title{
    align-items: center;
    justify-content: center;
    text-align: center;
}
.people_title h1{
    font-weight: 800;
}

.Box_1{
    height: 50vh;
    width: 100%;
    background-color: rgb(1,187,138);
    border-radius: 50%;
    margin-right: -10px;
    margin-left: 15px;
}
.Box_1 h1{
    padding-top: 100px;
    color: white;
    padding-left: 40px;
}
.Box_2 img{
    height: 50vh;
    width: 100%;
    border-radius: 190px 0px 0px 0px;
    
}

.Box_3{
    height: 50vh;
    width: 100%;
    background-color: rgb(237,113,121);
    border-radius: 0px 190px 0px 0px;
    margin-left: -10px;
}
.Box_3 h1{
    padding-top: 100px;
    color: white;
    padding-left: 40px;
    
}
.image_2 img{
    height: 100vh;
    width: 50vh;
    border-radius: 0px 0px 0px 190px;

}
.Box_4{
    height: 40vh;
    width: 100%;
    background-color: rgb(26,74,185);
    border-radius: 0px 0px 0px 190px;
}

.Box_4 h1{
    padding-top: 100px;
    color: white;
    padding-left: 40px;
}

.Box_5{
    height: 40vh;
    width: 100%;
    background-color: rgb(255,215,3);
border-radius: 50%;}

.Box_5 h1{
    padding-top: 100px;
    color: white;
    padding-left: 40px;
}
.image_3 img{
    height: 40vh;
    width: 50vh;
}

.Box_6{
    height: 60vh;
    width: 100%;
    background-color: rgb(237,113,121);
    border-radius: 0px 300px 0px 0px;
}

.Box_6 h1{
    padding-top: 100px;
    color: white;
    padding-left: 40px;
}

.Box_7{
    height: 60vh;
    width: 100%;
    background-color: rgb(1,187,138);
    border-radius: 50%;
    padding-left: 10px;
    margin-left: 10px;
}

.Box_7 h1{
    padding-top: 100px;
    color: white;
    padding-left: 40px;
}
.image_4 img{
    height: 60vh;
    width: 50vh;
    margin-left: -13px;

}
.Tech_sup {
  height: 25vh;
  width: 30vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 20px;
}
.Tech_sup h1 {
  color: rgb(215, 255, 246);
  font-size: 25vh;
  border-color: tomato;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  margin: 0;
}
.Tech_sup p {
  position: absolute;
  color: rgb(51, 130, 89);
  font-size: 40px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0; /* Remove default margin */
  font-weight: 500;
}
.img_content_tools img {
  height: 12vh;
}

.allthis {
    background-image: url('../../Labs/Aws/Assets/1.jpg');
    width: 100%;
    height: 130vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.contentt_001 h1,
.contentt_001 h3,
.contentt_001 p {
    color: white;
}

.btn1111 {
    background-color: rgb(17,105,213);
    color: white;
    border-radius: 0px 15px 15px 15px;
    font-size: 15px;
    margin-top: 10px;
    padding: 10px 20px;
    font-family: 'Poppins', sans-serif;
    height: 50px; /* Fixed height */
    width: auto; /* Auto width to fit content */
    line-height: 30px; /* Adjust if needed to vertically center text */
    text-align: center; /* Center the text horizontally */
    display: inline-block; /* Ensure the element respects the width and height */
    box-sizing: border-box; /* Include padding and border in the total width and height */
}

.text-muted {
    color: rgb(11, 32, 65);
}

.ul_01 ul {
    list-style-type: none;
    padding: 0;
}

.ul_01 li {
    color: rgb(130, 129, 129);
    padding: 5px 0;
}
.ul_01 h3{
    color: aqua;
}

.bottom_content {
    background-color: black;
    justify-content: center;
    display: flex;
    align-items: center;
}

.bottom_content h3 {
    color: aquamarine;
}

.bottom_content p {
    color: white;
}

.text-center {
    text-align: center;
}
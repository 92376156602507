.container_blog{
    height: 120vh;
    width: 100%;
    background-color: rgb(239,247,245);
}
.Blog_Studies {
    height: 25vh;
    width: 10vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .Blog_Studies h1 {
    color: rgb(231,238,237);
    font-size: 15vh;
    border-color: tomato;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    margin: 0;
  }
  
  .Blog_Studies p {
    position: absolute;
    color: rgb(14,43,63);
    font-size: 40px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-weight: 500;
  }
  
  .Blog_1 {
    margin: 10px 0;
  }

  .zoom-img {
    overflow: hidden;
  }
  
  .zoom-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .3s ease;
  }
  
  .zoom-img img:hover {
    transform: scale(1.2);
  }
  
  .Blog_1_sub {
    align-items: center;
    text-align: start;
    display: flex;
  }
  
  .Blog_1_sub p {
    padding-left: 100px;
  }
  
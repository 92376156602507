
.overall{
  border-radius: -2px;
  border: 1px solid #ccc;
 
  border-radius: 10px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  
}
.image-slider {
    position: relative;
    width: auto;
    height: 80px; /* Adjust the height as needed */
    overflow: hidden;
  }
  
  .slider-image {
    position: absolute;
    top: 0;
    left: 0;
   height: 80px;
   width: auto;
    object-fit: cover;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .slider-image.active {
    opacity: 1;
  }
  
.containerall{
background-color: rgba(255, 148, 55, 0.847) ;
}



.content_11ul{
    color: rgb(255, 0, 0);
}

.content_11ul a{
  text-decoration: none;
  color: black;
}
.list-group-item{
    color: rgba(255, 148, 55, 0.847);
}
.iconall {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .iconall i {
    color: rgb(255, 0, 0);
    margin: 0 10px; /* Adjust this value to set the gap between icons */
    font-size: x-large;
    margin-right: 30px;
  }
  .Tuni p{
    color: rgb(20, 18, 18);
    margin-left: 70vh;
    margin-top: 10px;
    font-size: large;
    font-weight: 800;
    
  }
  .content_11ul a:hover{
    color: aliceblue;
  }
.content_11ula a:hover{
  color: brown;
}
.iconall i:hover{
  color: aliceblue;
}
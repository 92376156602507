.people_career{
    height: 80vh;
    width: 100%;
    background-color: rgb(26,74,184);
    align-items: end;
    text-align: start;
    justify-content: end;
}
.people_career h1 {
    padding-top: 40vh;
    color: aliceblue;
    padding-left: 50px;
    font-size: 18vh;
}

.people_career p {
    color: aliceblue;
    padding-left: 50px;
}
/* Fastfact.css */

.Value_all {
    position: relative;
    overflow: hidden;
    height: 170vh; /* Adjust as needed */
    display: flex;
    flex-direction: column; /* Stack header and images vertically */
    justify-content: center;
    align-items: center;
}

.Value_all11 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px; /* Space between header and images */
}

.Value_all11 h1 {
    color: orangered;
    font-weight: 700;
    font-size: 3rem; /* Adjust the font size as needed */
}

.Value_all11 span {
    color: black;
    font-size: 2rem; /* Adjust the font size as needed */
    margin-top: 10px; /* Space above the span */
    font-weight: 700;
}

.Technology_User {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust min size to fit your design */
    gap: 20px; /* Adjust the gap between images */
    padding: 20px; /* Add some padding around the container */
    position: relative;
    width: 100%;
    height: auto; /* Adjust height as needed */
}

.Technology_User img {
    width: 100%;
    max-width: 280px; /* Set maximum width for images */
    height: auto; /* Maintain aspect ratio */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
    position: relative;
    margin: 0; /* Remove default margin */
}

.Technology_User img:hover,
.Technology_User img:active { /* :active for touch devices */
    transform: scale(1.1); /* Scale up the image */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow for effect */
}

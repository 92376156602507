.player-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden; /* Ensure the content stays within the wrapper */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; /* Place the video at the back */
  }
  
  .content {
    position: absolute;
   top: 40%;
   left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2; /* Place the content in front of the video */
    color: white;
    
    padding: 80px;
  }
  
  
  .btn101 {
    background-color: rgb(255, 73, 37);
    color: white;
    border-radius: 0px 15px 15px 15px;
    font-size: 15px;
    margin-right: 30px;
    padding: 10px 20px;
    margin-top: 10px;
    margin-left: 120px;
    border-color: rgb(255, 73, 37);
    font-family: 'Poppins', sans-serif;
    text-align: center;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    height: 50px;
    width: auto;
    line-height: 30px;
  }
  

  .content h1{
font-size: 50px;  
  color: white;
  font-weight: 900;
  height: 30vh;
  width: 200vh;
  }
  .content p{
  color: white;
  height: 30vh;
  width: 200vh;
  padding-left: 20px;
  }

  
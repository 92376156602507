.image1 {
    border-radius: 10px; /* Adjust the value as needed */
    border: 2px solid #ccc; /* Optional: Add a border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow */
    padding: 5px; /* Optional: Add padding */
    transition: all 0.3s ease-in-out; /* Optional: Smooth transition for hover effects */
}

.image1:hover {
    transform: scale(1.05); /* Optional: Slightly scale up the image on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Optional: Enhance the shadow on hover */
}

.overall_1 {
  margin: 6% 6% 0 3%;
  position: relative;
  width: "auto";
  height: "500px";
  
}
.image1 {
    display: block;
   border-image-width: auto;
  }

   .overlay {
    position: absolute;
    background-color: rgba(6, 5, 11, 0.6);
    overflow: hidden;
    width: 89%;
    height: 0;
    transition: 0.5s ease;
    bottom: 10px;
    right: 20px;
    border-radius: -2px;
    border: 1px solid #ccc;
   
    border-radius: 10px;

    

  }
  .overall_1:hover .overlay {
    height: 70%;

  }
  .icon_1{
    color: white;
  }
  .icon_2{
    color: white;
  }
  .icon_3{
    color: white;
  }
  
  .icon_3 i{
    color: blue;
  }
  .icon_2 i{
    color: greenyellow;
  }
  .icon_1 i{
    color: blueviolet;
  }
  .overlay h5 {
    text-align: center;
    margin-top: 10%;
    width: 90%;
    color: white;
    font-size: 20px;
  }
  .overlay p {
    text-align: center;
    color: white;
    position: absolute;
    bottom: 10%;
    width: 90%;
  }
  
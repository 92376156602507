* {
    margin: 0;
    padding: 0;
}

.text_design {
    color: blue;
    width: 40px;
    height: 8vh;
}

.card1 {
    display: flex;
    color: rgba(0, 0, 0, 0.8);
    letter-spacing: -0.32px;
    font-family: Poppins, sans-serif;
    font-size: 1rem;
    line-height: 160%;
    background-color: lightblue;
    border-left: 4px solid #F24495;
}

.text1222 h1,
.text5555 h1,
.text66666 h1,
.text332 h1,
.text333 h1,
.text334 h1 {
    font-size: 10vh;
}

.Back {
    background-color: rgb(208, 226, 237);
    margin-top: 10px;
    height: 100vh;
    width: 100%;
}

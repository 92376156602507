.overall_01{
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
}
.overall_02{
    padding-top: 5vh;
}
.title{
    padding-left: 30px;
    color: white;
    
}

.paragraph{
    padding-left: 30px;
    color: white;
    margin-top: 20px;
}
.btn2{
    background-color:white;
    color: rgb(255,73,37);
    border-radius: 0px 15px 15px 15px;
    font-size: 15px;
    margin-right: 30px;
    padding: 10px 20px 10px 20px;
    border-color: transparent;
        font-family: poppins,sans-serif;
        margin-top: 20px;
        margin-left: 20px;
}
.image img{
    border-radius:  400vh 0vh 0vh 400vh;
}
.back_color1{
background-color: rgb(59, 14, 205);}
.back_color2{
  background-color: rgb(89, 154, 89);
}
.back_color3{
    background-color: rosybrown;
}
.Header_Overall0001 {
  background-color: white;
  height: 16vh;
  margin-top: -10vh; /* This should work, but let's add some adjustments */
  position: relative; /* Add position relative */
  top: -10vh; /* Alternative to margin-top */
}

.imgaelogo img {
  height: 50px;
  margin-right: 30px;
}

.Header_Overall_Contantbox {
  display: flex;
  align-items: center;
  justify-content: start;
}
.Header_Overall_Contantbox  a:hover{
  color: orangered;
}
.btn02 {
  background-color: rgb(255, 73, 37);
  color: white;
  border-radius: 0px 15px 15px 15px;
  font-size: 15px;
  margin-right: 10px;
  padding: 10px 20px 10px 20px;
  border-color: transparent;
  font-family: poppins, sans-serif;
  margin-top: 5px;
  margin-left: 20px;
}

.btn02:hover {
  background-color: rgb(17, 17, 17);
}

.link1 a {
  color: black;
}

.value_content {
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  
  .BOX_01 {
    height: 25vh;
    width: 30vh;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 20px;
    box-shadow: 10px 10px 10px  #888888;

  }
  
  .BOX_01 h1 {
    color: rgb(232,236,248);
    font-size: 25vh;
    border-color: tomato;
    border-radius: 10px;
    position: relative; 
    z-index: 1; 
    margin: 0; 

  }
  
  .BOX_01 p {
    position: absolute;
    color: rgb(26,73,185);
    font-size: 40px;
    z-index: 2; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0; /* Remove default margin */
    font-weight: 500;
  }
  
  
  .BOX_02 {
    height: 25vh;
    width: 30vh;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 20px;
    box-shadow: 10px 10px 10px  #888888;

  }
  
  .BOX_02 h1 {
    color: rgb(253,240,241);
    font-size: 25vh;
    border-color: tomato;
    border-radius: 10px;
    position: relative; 
    z-index: 1; 
    margin: 0; 
  }
  
  .BOX_02 p{
    position: absolute;
    color: rgb(239,113,121);
    font-size: 40px;
    z-index: 2; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0; /* Remove default margin */
    font-weight: 500;
  }
  
  .BOX_03 {
    height: 25vh;
    width: 30vh;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 20px;
    box-shadow: 10px 10px 10px  #888888;

  }
  
  .BOX_03 h1 {
    color: rgb(229,248,243);
    font-size: 25vh;
    border-color: tomato;
    border-radius: 10px;
    position: relative; 
    z-index: 1; 
    margin: 0; 
  }
  
  .BOX_03 p {
    position: absolute;
    color: rgb(0,182,134);
    font-size: 40px;
    z-index: 2; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0; /* Remove default margin */
    font-weight: 500;
  }
  
  .BOX_04 {
    height: 25vh;
    width: 30vh;
    position: relative; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 20px;
    box-shadow: 10px 10px 10px  #888888;

  }
  
  .BOX_04 h1 {
    color:rgb(255,249,217);
    font-size: 25vh;
    border-color: tomato;
    border-radius: 10px;
    position: relative; 
    z-index: 1; 
    margin: 0; 
  }
  
  .BOX_04 p {
    position: absolute;
    color: rgb(255,215,3);
    font-size: 40px;
    z-index: 2; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0; /* Remove default margin */
    font-weight: 500;
  }
  
  .Benefits_1{
    height: 40vh;
    width: 40vh;
    background-color:rgb(232,236,248);
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px;
    border-radius: 20px;
    margin: 10px;
  }
.Benefits_1 i{
    font-size: 10vh;
    color: rgb(26,73,185); 
}
.Benefits_2{
    height: 40vh;
    width: 40vh;
    background-color:rgb(253,240,241);
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px;
    border-radius: 20px;
    margin: 10px;

  }
.Benefits_2 i{
    font-size: 10vh;
    color: rgb(239,113,121); 
}
.Benefits_3{
    height: 40vh;
    width: 40vh;
    background-color:rgb(229,248,243);
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px;
    border-radius: 20px;
    margin: 10px;

  }
.Benefits_3 i{
    font-size: 10vh;
    color: rgb(0,182,134); 
}
.Benefits_4{
    height: 40vh;
    width: 40vh;
    background-color:rgb(255,249,217);
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px;
    border-radius: 20px;
    margin: 10px;

  }
.Benefits_4 i{
    font-size: 10vh;
    color: rgb(255,215,3); 
}


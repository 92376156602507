*{
    padding: 0;
    margin: 0;
}

.Ai_img  img{
    background-color: brown;
    height: 80vh;
    width: 90vh;
}

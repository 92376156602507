.container_CaseStudies {
  background-color: rgb(14, 43, 63);
  margin-top: 60px;
}
.CaseStudies_Overall {
  height: 120vh;
}
.Case_Studies {
  height: 25vh;
  width: 30vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: start;
}

.Case_Studies h1 {
  color: rgb(25, 54, 69);
  font-size: 15vh;
  border-color: tomato;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  margin: 0;
}

.Case_Studies p {
  position: absolute;
  color: rgb(255, 255, 255);
  font-size: 40px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-weight: 500;
}

.image_1 {
  border-radius: 10px;
  border: 2px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 5px;
  transition: all 0.3s ease-in-out;
}

.image_1:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.Case_all {
  margin: 6% 6% 0 0%;
  position: relative;
  height: 500px;
}

.Case_overlay {
  position: absolute;
  background-color: rgba(6, 5, 11, 0.6);
  overflow: hidden;
  width: 89%;
  height: 0;
  transition: 0.5s ease;
  bottom: 10px;
  right: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.Case_all:hover .Case_overlay {
  height: 70%;
}

.icon_1,
.icon_2,
.icon_3 {
  color: white;
}

.icon_3 i {
  color: blue;
}

.icon_2 i {
  color: greenyellow;
}

.icon_1 i {
  color: blueviolet;
}

.Case_overlay h5 {
  text-align: center;
  margin-top: 10%;
  width: 90%;
  color: white;
  font-size: 20px;
}

.Case_overlay p {
  text-align: center;
  color: white;
  position: absolute;
  bottom: 10%;
  width: 90%;
}

.container_0001 {
  padding: 0 15px;
}


.title_0001, .title_0002 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.content_02 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.overall_001 {
  height: 250px;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 40px;
  display: flex;
}

.overall_001 img {
  width: 220px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;
}

.overall_001 .content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.overall_001:hover .content {
  opacity: 1;
}

.overall_001:hover img {
  transform: scale(1.1);
}

.content_01 h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.content_01 p {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.content_02 a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}

.content_02 p {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.content_02 a:hover {
  text-decoration: underline;
}

.bi-arrow-right {
  margin-left: 5px;
}

.Service_Content_01{
    background-color: rgb(248,243,237) ;
    display: flex;
    align-items: center;
    height: 120vh;
}
.Services_content01 p {
    padding: 10px;
    color: rgb(14, 14, 14);
    line-height: 1.8; 
    font-size: 18px;
    margin-bottom: 20px; 
    font-family: oblique 40deg;
}

.icon_li i {
    color: rgb(255, 123, 0);
}

.icon_li li {
    padding: 10px;
    font-family: oblique 40deg;

}
.Services_content01 h3 {
    padding-top: 25px; 
    font-family: oblique 40deg;
    font-weight: 700;
  
}

.Image_service01 img{
    padding-top: 80px;
    width: 70vh;
    height: 100vh;
    border-radius: 200px 0px 0px 0px;
}


.Service_Content_02{
    background-color: rgb(241,241,241);
    display: flex;
    align-items: center;
    height: 120vh;
}
.Services_content02 p {
    padding: 10px;
    color: rgb(14, 14, 14);
    line-height: 1.8; 
    font-size: 18px;
    margin-bottom: 20px; 
    font-family: oblique 40deg;
}

.App1 h1{
    color: lightslategray;

}

.icon_li i {
    color: rgb(255, 123, 0);
}

.icon_li li {
    padding: 10px;
    font-family: oblique 40deg;

}
.Services_content02 h3 {
    padding-top: 25px; 
    font-family: oblique 40deg;
    font-weight: 700;
  
}

.Image_service02 img{
    padding-top: 40px;
    width: 80vh;
    height: 100vh;
    border-radius: 0px 200px 0px 0px;
}


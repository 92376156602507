.details{
    background-color: rgb(42, 42, 160);
    /* text-align: center; */
    text-decoration:underline;
    text-anchor: middle;
    /* width: 850px; */
     height: 50vh; 
     border-radius: 2px;
    
}
.backimg_container{
     
    background-color: rgb(237,245,255);
}
.details:hover{

    color: white;
}
.Advance_img img{
    height: 80vh;
    width: auto;
    padding-right: 20px;
}
.detailsrounded{
    background-color: rgb(26,74,185);
    height: 70vh;
    width: 90vh;
    border-radius: -2px;
    border: 1px solid #ccc;
    border-radius: 20px;

}
.detailsrounded p{
    color: #ccc;
    padding-top: 25px;
}
.heading_1 h1{
    color: black;
    font-weight: 750;
}

.Button_ContactUs button {
  background-color: rgb(255, 73, 37);
  color: white;
  border-radius: 0px 15px 15px 15px;
  font-size: 15px;
  margin-right: 30px;
  padding: 10px 20px;
  border-color: transparent;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
  margin-left: 20px;
}

.BankConnect_contentimg img {
  height: 100vh;
  width: 100%;
}

.BankConnect_contentimg {
  height: 100vh;
  width: 100%;
  position: relative;
}

.BankConnect_content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-family: "Poppins", sans-serif;
  height: 40vh;
  width: 100vh;
}
.BankConnect_content h1 {
  height: 15vh;
  width: auto;
}

.text-center{
  text-align: center;
}

.border{
    border-radius: 10px;
    display: flex;
}

.Test_details{
    
    height: 60vh;
    /* background-image: linear-gradient(blue , pink); */
    translate: 2px;
    color: white;
    font-size: medium;
    translate: 3px;
    /* background-color: blue; */
    background-image: linear-gradient(top right rgb(37, 37, 154), rgb(92, 92, 26));
}
.bginfo{
    /* background-image: url('../Testimonals/Assets/13.png'); */
    
    border-radius: 50px;
    height: 110vh;
  
    padding-bottom: 20px;
}
 .info{
    color: white;
    background-color: rgb(16,105,213);
    /* background-color: ; */
    width:100%;
    height: auto;
} 

.info1 {
    background: url('../Testimonals/Assets/12.png'),linear-gradient(to bottom left, rgb(26,74,185) 50%, rgb(254,214,3) 50%), blue;
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
   
}



.imgg{
  height:10vh;
  top: 5px;
  /* width:5px */
  padding: 5px;
  margin:5px

}

.txt{
    font-size: 25px;
}
.txt p{
    color: black;
    font-weight: 900;
    padding: 10px;
    font-size: xx-large;
    
}


.bg1 {
    max-height: max-content;
    margin-top: 40px;
    /* justify-content: center; */
    /* text-align: center; */
    /* font-weight: 20px; */
    font-size: 70px;

}

.info  img{
    height:50vh;
    width:auto;
    border-width:2px;
    /* margin-bottom: 50px; */
    
}



/* Talktous.css */

.container_FastTalk {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.all {
  background-image: url("../Talktous/Assests/8.gif");
  width: 100%;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
}

.content_1, .contactForm {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  flex: 1;
}

.content_1 {
  background-color: rgb(0, 182, 134);
  color: white;
}

.contact-info {
  margin-top: 20px;
}

.contact-info div {
  margin: 10px 0;
  font-size: 1.2rem;
}

.content1_image img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 20px;
}

.contactForm h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}
.contactForm{
  background-color: transparent;
}
.contactForm .input-box {
  margin-bottom: 45px;
}

.contactForm .input-box input, .contactForm .input-box textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 1rem;
}

.contactForm .input-box textarea {
  resize: vertical;
}

.contactForm .butn {
  background-color: rgb(255, 73, 37);
  color: rgb(255, 255, 255);
  border-radius: 0 15px 15px 15px;
  font-size: 1rem;
  width: 100%;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
}

.contactForm .butn:hover {
  background-color: rgb(230, 60, 30);
}

.overall_0001{
    gap: 20px;
}
.box_0001{
    background-color: blue;
    border: 1px solid #ccc;
  
    border-radius: 10px;
}
.image_con1 img{
    background-color: brown;
    height: 55vh;
    width: 80vh;
}
.logo_con img{
    height: 20vh;
    width: 30vh;
}
.con2_text{
    padding-top: 20px;
}
.con2{
    background-color: blue;
}
.con2_text {
    color: aliceblue;
    padding: 20px;
}

.content_001{
    border: 1px solid #ccc;
    height: 80vh;
    width: auto;    
    border-radius: 10px;
    padding: 50px 20px 10px 20px;
}
.content_002{
    border: 1px solid #ccc;
    height: 80vh;
    width: auto;    
    border-radius: 10px;
    padding: 50px 20px 10px 20px;
}

.box_001 i{
    font-size: xx-large;
  
    color: red;
}
.box_002 i{
    font-size: xx-large;
  
    color: rgb(6, 140, 31);
}
.box_003 i{
    font-size: xx-large;
  
    color: rgb(72, 26, 141);
}
.box_004 i{
    font-size: xx-large;
  
    color: rgb(225, 255, 0);
}

